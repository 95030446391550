import React, { useContext, useEffect } from 'react';
import PhoneLogContext from '../PhoneLogContext';
import usePhoneLogs from '../usePhoneLogs';
import { RestauInfoContext } from '../../../../home/RestauInfoContext';
import PhoneLogContent from '../Components/PhoneLogContent';

function PhoneLogsRoot() {
    const phoneLogsHook = usePhoneLogs();
    const { restauHook } = useContext(RestauInfoContext);

    useEffect(() => {
        phoneLogsHook.fetchSubscriptionPlanData();
    }, [restauHook.restauId]);

    return (
        <PhoneLogContext.Provider value={phoneLogsHook}>
            <PhoneLogContent />
        </PhoneLogContext.Provider>
    );
}

PhoneLogsRoot.propTypes = {};

export default PhoneLogsRoot;
